import React from 'react';
import SEO from '../components/seo';
import { Box, ButtonBase, Container, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
  flexContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  spacer: {
    padding: theme.spacing(7, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  iconButton: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  buttonsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

export default function EULA() {
  const classes = useStyles();

  return (
    <>
      <SEO title="End-User License Agreement" />
      <section className={classes.root}>
        <Container>
          <Typography variant="h1" align="center" gutterBottom>
            End-User License Agreement ("Agreement")
          </Typography>
          <Typography variant="subtitle" paragraph align="center">
            Last updated: February 25, 2021
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Please read this End-User License Agreement ("Agreement") carefully before clicking the "I Agree" button,
            downloading or using FamPro ("Application"). By clicking the "I Agree" button, downloading or using the
            Application, you are agreeing to be bound by the terms and conditions of this Agreement. This Agreement is a
            legal agreement between you (either an individual or a single entity) and FamPro, Inc. and it governs your
            use of the Application made available to you by FamPro, Inc.. If you do not agree to the terms of this
            Agreement, do not click on the "I Agree" button and do not download or use the Application. The Application
            is licensed, not sold, to you by FamPro, Inc. for use strictly in accordance with the terms of this
            Agreement.
          </Typography>
          <Typography variant="h1" align="center" gutterBottom>
            License
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. grants you a revocable, non-exclusive, non-transferable, limited license to download, install
            and use the Application strictly in accordance with the terms of this Agreement.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Restrictions
          </Typography>
          <Typography variant="h2" paragraph align="center">
            You agree not to, and you will not permit others to: license, sell, rent, lease, assign, distribute,
            transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the
            Application available to any third party. copy or use the Application for any purpose other than as
            permitted under the above section 'License'. modify, make derivative works of, disassemble, decrypt, reverse
            compile or reverse engineer any part of the Application. remove, alter or obscure any proprietary notice
            (including any notice of copyright or trademark) of FamPro, Inc. or its affiliates, partners, suppliers or
            the licensors of the Application.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Intellectual Property
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The Application, including without limitation all copyrights, patents, trademarks, trade secrets and other
            intellectual property rights are, and shall remain, the sole and exclusive property of FamPro, Inc..
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            In-app Purchases
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The Application may include In-app Purchases that allow you to buy products, services or Subscriptions. More
            information about how you may be able to manage In-app Purchases using your Device may be set out in the
            Application Store's own terms and conditions or in your Device's Help settings. In-app Purchases can only be
            consumed within the Application. If you make a In-app Purchase, that In-app Purchase cannot be cancelled
            after you have initiated its download. In-app Purchases cannot be redeemed for cash or other consideration
            or otherwise transferred. If any In-app Purchase is not successfully downloaded or does not work once it has
            been successfully downloaded, we will, after becoming aware of the fault or being notified to the fault by
            You, investigate the reason for the fault. We will act reasonably in deciding whether to provide You with a
            replacement In-app Purchase or issue You with a patch to repair the fault. In no event will We charge You to
            replace or repair the In-app Purchase. In the unlikely event that we are unable to replace or repair the
            relevant In-app Purchase or are unable to do so within a reasonable period of time and without significant
            inconvenience to You, We will authorize the Application Store to refund You an amount up to the cost of the
            relevant In-app Purchase. Alternatively, if You wish to request a refund, You may do so by contacting the
            Application Store directly. You acknowledge and agree that all billing and transaction processes are handled
            by the Application Store from where you downloaded the Application and are governed by that Application
            Store's own terms and conditions. If you have any payment related issues with In-app Purchases, then you
            need to contact the Application Store directly.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Your Suggestions
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to
            FamPro, Inc. with respect to the Application shall remain the sole and exclusive property of FamPro, Inc..
            FamPro, Inc. shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose
            and in any way without any credit or any compensation to you.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Modifications to Application
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. reserves the right to modify, suspend or discontinue, temporarily or permanently, the
            Application or any service to which it connects, with or without notice and without liability to you.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Updates to Application
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. may from time to time provide enhancements or improvements to the features/functionality of the
            Application, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").
            Updates may modify or delete certain features and/or functionalities of the Application. You agree that
            FamPro, Inc. has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any
            particular features and/or functionalities of the Application to you. You further agree that all Updates
            will be (i) deemed to constitute an integral part of the Application, and (ii) subject to the terms and
            conditions of this Agreement.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Third-Party Services
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The Application may display, include or make available third-party content (including data, information,
            applications and other products services) or provide links to third-party websites or services ("Third-Party
            Services"). You acknowledge and agree that FamPro, Inc. shall not be responsible for any Third-Party
            Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality,
            decency, quality or any other aspect thereof. FamPro, Inc. does not assume and shall not have any liability
            or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services
            and links thereto are provided solely as a convenience to you and you access and use them entirely at your
            own risk and subject to such third parties' terms and conditions.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. collects, stores, maintains, and shares information about you in accordance with its Privacy
            Policy, which is available at http://FamProhq.com/privacy-policy. By accepting this Agreement, you
            acknowledge that you hereby agree and consent to the terms and conditions of our Privacy Policy.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Term and Termination
          </Typography>
          <Typography variant="h2" paragraph align="center">
            This Agreement shall remain in effect until terminated by you or FamPro, Inc.. FamPro, Inc. may, in its sole
            discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior
            notice. This Agreement will terminate immediately, without prior notice from FamPro, Inc., in the event that
            you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting
            the Application and all copies thereof from your mobile device or from your computer. Upon termination of
            this Agreement, you shall cease all use of the Application and delete all copies of the Application from
            your mobile device or from your computer. Termination of this Agreement will not limit any of FamPro, Inc.'s
            rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any
            of your obligations under the present Agreement.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Indemnification
          </Typography>
          <Typography variant="h2" paragraph align="center">
            You agree to indemnify and hold FamPro, Inc. and its parents, subsidiaries, affiliates, officers, employees,
            agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys'
            fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law
            or regulation; or (c) violation of any right of a third party.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            No Warranties
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The Application is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without
            warranty of any kind. To the maximum extent permitted under applicable law, FamPro, Inc., on its own behalf
            and on behalf of its affiliates and its and their respective licensors and service providers, expressly
            disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application,
            including all implied warranties of merchantability, fitness for a particular purpose, title and
            non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or
            trade practice. Without limitation to the foregoing, FamPro, Inc. provides no warranty or undertaking, and
            makes no representation of any kind that the Application will meet your requirements, achieve any intended
            results, be compatible or work with any other software, applications, systems or services, operate without
            interruption, meet any performance or reliability standards or be error free or that any errors or defects
            can or will be corrected. Without limiting the foregoing, neither FamPro, Inc. nor any FamPro, Inc.'s
            provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or
            availability of the Application, or the information, content, and materials or products included thereon;
            (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or
            currency of any information or content provided through the Application; or (iv) that the Application, its
            servers, the content, or e-mails sent from or on behalf of FamPro, Inc. are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components. Some jurisdictions do not allow the exclusion
            of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer,
            so some or all of the above exclusions and limitations may not apply to you.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Limitation of Liability
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Notwithstanding any damages that you might incur, the entire liability of FamPro, Inc. and any of its
            suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be
            limited to the amount actually paid by you for the Application. To the maximum extent permitted by
            applicable law, in no event shall FamPro, Inc. or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits,
            for loss of data or other information, for business interruption, for personal injury, for loss of privacy
            arising out of or in any way related to the use of or inability to use the Application, third-party software
            and/or third-party hardware used with the Application, or otherwise in connection with any provision of this
            Agreement), even if FamPro, Inc. or any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose. Some states/jurisdictions do not allow the exclusion or
            limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to
            you.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Severability
          </Typography>
          <Typography variant="h2" paragraph align="center">
            If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed
            and interpreted to accomplish the objectives of such provision to the greatest extent possible under
            applicable law and the remaining provisions will continue in full force and effect.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Waiver
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Except as provided herein, the failure to exercise a right or to require performance of an obligation under
            this Agreement shall not effect a party's ability to exercise such right or require such performance at any
            time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Amendments to this Agreement
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If
            a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole discretion. By continuing to access or use our
            Application after any revisions become effective, you agree to be bound by the revised terms. If you do not
            agree to the new terms, you are no longer authorized to use the Application.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Governing Law
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The laws of Illinois, United States, excluding its conflicts of law rules, shall govern this Agreement and
            your use of the Application. Your use of the Application may also be subject to other local, state,
            national, or international laws.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Governing Law
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
            Service. Your use of the Application may also be subject to other local, state, national, or international
            laws.
          </Typography>
          <Typography variant="h1" align="center" gutterBottom>
            Contact Information
          </Typography>
          <Typography variant="h2" paragraph align="center">
            If you have any questions about this Agreement, please contact us.
          </Typography>
          <Typography variant="h1" align="center" gutterBottom>
            Entire Agreement
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The Agreement constitutes the entire agreement between you and FamPro, Inc. regarding your use of the
            Application and supersedes all prior and contemporaneous written or oral agreements between you and FamPro,
            Inc.. You may be subject to additional terms and conditions that apply when you use or purchase other
            FamPro, Inc.'s services, which FamPro, Inc. will provide to you at the time of such use or purchase.
          </Typography>
        </Container>
      </section>
    </>
  );
}
